import { Event } from '../timeline/event';
import { get, put, ui } from '../utils/storage-impl';

import { ConnectionMode } from './connection-mode';
import { checkContinue, initLatest, updateLatest } from './connection-utils';
import { Pairing } from './pairing';
import { Sync } from './sync';

const IDLE_MS_BETWEEN_SYNCS = 500;

const latest = initLatest();
const timeouts: Record<string, NodeJS.Timeout> = {};
const cursor = [0]; // 0 is now
const eventChangeNo = {};
const infoChangeNo = {};
const layoutChangeNo = {};

export function setStorageCursor(time: number) {
    cursor[0] = time;
}

export function storagePairings(sync: Sync): Array<Pairing> {
    return Object.keys(sync.pairings)
        .map((k) => sync.pairings[k])
        .filter((p) => p.active && p.sync === ConnectionMode.STORAGE);
}

export async function getStorage(
    sync: Sync,
    pairing: Pairing,
    onEvent: (event: Event, userEvent: boolean) => void,
    onLayout: (layout: string, userEvent: boolean) => void
) {
    updateLatest(latest, sync, onEvent, onLayout);

    eventChangeNo[pairing.pairing] = +get('eventChangeNo-' + pairing.pairing) ?? 0;
    infoChangeNo[pairing.pairing] = +get('infoChangeNo-' + pairing.pairing) ?? 0;
    layoutChangeNo[pairing.pairing] = +get('layoutChangeNo-' + pairing.pairing) ?? 0;
    const url = (pairing.displayName.includes('://') ? '' : 'https://') + `${pairing.displayName}/${pairing.channel}/${pairing.pairing}`;

    poll(sync.version, 0, pairing, url);
}

async function poll(version: number, idle: number, pairing: Pairing, url: string) {
    if (!checkContinue(latest, version, [pairing.pairing])) {
        return;
    }
    if (timeouts[pairing.channel]) {
        clearTimeout(timeouts[pairing.channel]);
        delete timeouts[pairing.channel];
    }

    const newTimeout = setTimeout(
        async () => {
            if (timeouts[pairing.channel] === newTimeout) {
                let extraIdle = 0;
                const start = new Date().getTime();
                try {
                    const at = !!cursor[0] ? cursor[0] : new Date().getTime();
                    const res = await fetch(
                        `${url}?a=${at}&e=${eventChangeNo[pairing.pairing] ?? 0}&i=${infoChangeNo[pairing.pairing] ?? 0}&l=${
                            layoutChangeNo[pairing.pairing] ?? 0
                        }`
                    );
                    if (res.status == 200) {
                        const body = await res.json();
                        if (body) {
                            if (body['eventChangeNo']) {
                                eventChangeNo[pairing.pairing] = +body['eventChangeNo'];
                                put('eventChangeNo-' + pairing.pairing, eventChangeNo[pairing.pairing]);
                            }
                            if (body['infoChangeNo']) {
                                infoChangeNo[pairing.pairing] = +body['infoChangeNo'];
                                put('infoChangeNo-' + pairing.pairing, infoChangeNo[pairing.pairing]);
                            }
                            if (body['layoutChangeNo']) {
                                layoutChangeNo[pairing.pairing] = +body['layoutChangeNo'];
                                put('layoutChangeNo-' + pairing.pairing, layoutChangeNo[pairing.pairing]);
                            }
                            if (body['data']) {
                                const a = body['data'] as Array<unknown>;
                                a.forEach((v) => {
                                    if (v['s']) {
                                        latest.onEvent(v as Event, false);
                                    } else {
                                        latest.onLayout(JSON.stringify(v), false);
                                    }
                                });
                            }
                        }
                    }
                    if (res.status > 204) {
                        extraIdle = 15000 - (new Date().getTime() - start);
                    }
                } catch (e) {
                    extraIdle = 15000 - (new Date().getTime() - start);
                }
                poll(version, extraIdle, pairing, url);
            }
        },
        Math.max(IDLE_MS_BETWEEN_SYNCS, idle)
    );

    timeouts[pairing.channel] = newTimeout;
}

export async function postStorage(pairing: Pairing, at: number, e: Event | string) {
    await fetch((pairing.displayName.includes('://') ? '' : 'https://') + `${pairing.displayName}/${pairing.channel}/${ui()}?a=${at}`, {
        body: e['s'] ? JSON.stringify([e]) : `[${e}]`,
        method: 'POST',
        keepalive: true,
    });
}
