import EventEmitter from 'events';

import { Box, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { RefCallback, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';

import { getChannels, postChannel } from './connect/channel-utils';
import { ConnectionMode } from './connect/connection-mode';
import { correctDisplayName, generatePairingKey } from './connect/connection-utils';
import { Pairing } from './connect/pairing';
import { getStorage, postStorage, setStorageCursor, storagePairings } from './connect/storage-utils';
import { Sync } from './connect/sync';
import { Event } from './timeline/event';
import { TimelineComponent } from './timeline/Timeline.component';
import { useTimeline } from './timeline/use-timeline.hook';
import { Toolbar } from './toolbar/Toolbar';
import { Cell } from './tracking/cell';
import { CellMode } from './tracking/cell-mode';
import { TrackingComponent } from './tracking/Tracking.component';
import { clone } from './utils/clone';
import { csvTable } from './utils/csv-utils';
import { addEvent, getEvents } from './utils/event-utils';
import { initialLayout } from './utils/layout-utils';
import { KEY_CONNECTIONS, KEY_LAYOUT } from './utils/locale.storage.keys';
import { clear, ui } from './utils/storage-impl';
import { useConfig } from './utils/use-config';
import { useDelayed } from './utils/use-delayed';

const action = new EventEmitter();

type ComponentProps = {
    themeAction: EventEmitter;
};

export function Dashboard({ themeAction }: ComponentProps) {
    const { t } = useTranslation();
    const theme = useTheme();

    const [layout, setLayout] = useConfig<string>(KEY_LAYOUT, initialLayout(t));
    const [mode, setMode] = useState<CellMode>(CellMode.JUMP);
    const [sync, setSyncInternal] = useConfig<Sync>(KEY_CONNECTIONS, { pairings: {}, version: 0 });
    const setSync = useCallback(
        (newSync: Sync) => {
            if (mode === CellMode.QR) {
                setMode(CellMode.JUMP);
            }
            newSync.version = !sync.version ? 1 : sync.version + 1;
            setSyncInternal(newSync);
        },
        [sync, setSyncInternal, mode, setMode]
    );
    const { cursor, focus, saveNow, zoom, updateNow, setCursor, setZoom } = useTimeline();

    const [cursorEvent, setCursorEvent] = useState<Event>();
    const [info, setInfo] = useState('');
    const messageBar = useSnackbar();

    const [searchParams] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { ref } = useSwipeable({
        onSwipedLeft: () => navigate('/report'),
        onSwipedDown: () => navigate(location.pathname === '/export' ? '/' : '/connections'),
        onSwipedUp: () => navigate(location.pathname === '/connections' ? '/' : '/export'),
    }) as unknown as {
        ref: RefCallback<Document>;
    };

    const { onDelay, clearDelay } = useDelayed(3000);

    useEffect(() => {
        if (['reset'].includes(searchParams.get('a') as string)) {
            clear();
            navigate('/');
        }
        ref(document);

        // QR-Code configure sync/storage connections and redirect to '/' thereafter
        if (['a', 'v', 'p', 'e', 's', 'i'].includes(searchParams.get('m') as string)) {
            const newSync = clone(sync);

            // correct old syncs
            Object.keys(newSync.pairings).forEach((k) => {
                if (!newSync.pairings[k].channel) {
                    newSync.pairings[k].channel = ui();
                }
            });
            delete newSync['active'];
            delete newSync['channel'];

            const channel = searchParams.get('c') ?? ui();
            let syncMode = undefined;
            switch (searchParams.get('m')) {
                case 'a': // connect via stream
                    syncMode = ConnectionMode.SAME_USER;
                    break;
                case 'v': // connect via /view/ url
                    syncMode = ConnectionMode.SPECTATOR;
                    break;
                case 'p': // connect via badge
                    syncMode = ConnectionMode.PEEP;
                    break;
                case 'e': // push-jump-events-only via stream
                    syncMode = ConnectionMode.QRCODE_PUSH;
                    break;
                case 's': // storage
                    syncMode = ConnectionMode.STORAGE;
                    break;
                case 'i': // invoice
                    syncMode = ConnectionMode.INVOICE;
                    break;
                default:
                    navigate('/');
                    return;
            }
            const pairingKey = generatePairingKey(syncMode, searchParams.get('p'));
            const displayName =
                syncMode === ConnectionMode.INVOICE
                    ? searchParams.get('d') ?? 'localhost:10801'
                    : correctDisplayName(newSync, pairingKey, syncMode, searchParams.get('d'));
            const pairing = sync.pairings[pairingKey]
                ? sync.pairings[pairingKey]
                : ({ active: true, channel, pairing: pairingKey, sync: syncMode, displayName } as Pairing);
            if (newSync.pairings[pairingKey] !== undefined) {
                delete newSync.pairings[pairingKey];
            }
            newSync.pairings[pairingKey] = pairing;
            setSync(newSync);
            navigate('/');
        }

        return () => {
            ref({} as Document);
        };
    }, [sync, setSync, searchParams, navigate, ref]);

    const onCursorChanged = useCallback(
        (time: number, isNow: boolean) => {
            const eventsAtTime = getEvents(time, time);
            const events = eventsAtTime.filter((e) => e.s <= time && e.n && (!e.e || e.e > time));
            if (events.length) {
                const event = events[events.length - 1];
                setCursorEvent(event);
                document.title = event.n + ' - Time2Emphasize';
            } else {
                setCursorEvent(undefined);
                document.title = 'Time2Emphasize';
            }
            setCursor(time);
            setStorageCursor(isNow ? 0 : time);
            const info = eventsAtTime.filter((e) => e.s === time && !!e.i);
            if (info.length) {
                setInfo(info[0].i as string);
            } else {
                setInfo('');
            }
        },
        [setCursor, setCursorEvent, setInfo]
    );

    const propagateEvent = useCallback(
        (event: Event, userTriggered = true) => {
            if (!event.n && !event.i) {
                setMode(CellMode.OFF);
            } else {
                addEvent(event);
            }
            const now = updateNow();
            onCursorChanged(now, true);
            if (userTriggered) {
                Object.values(sync.pairings).forEach(async (pairing) => {
                    if (pairing.active) {
                        if (pairing.sync === ConnectionMode.STORAGE) {
                            await postStorage(pairing, cursor, event);
                        } else if ([ConnectionMode.SAME_USER, ConnectionMode.SPECTATOR, ConnectionMode.PEEP].includes(pairing.sync)) {
                            await postChannel(pairing, event);
                        }
                    }
                });
            }
        },
        [sync, cursor, updateNow, onCursorChanged]
    );

    const propagateLayout = useCallback(
        (l: string, userTriggered = true) => {
            if (l !== layout) {
                try {
                    Cell.fromJson(l);
                } catch (e) {
                    return;
                }
                setLayout(l);
                if (userTriggered) {
                    onDelay(() => {
                        clearDelay();
                        Object.values(sync.pairings).forEach(async (pairing) => {
                            if (pairing.active) {
                                if (pairing.sync === ConnectionMode.STORAGE) {
                                    await postStorage(pairing, cursor, l);
                                } else if ([ConnectionMode.SAME_USER, ConnectionMode.SPECTATOR].includes(pairing.sync)) {
                                    await postChannel(pairing, l);
                                }
                            }
                        });
                    });
                }
            }
        },
        [layout, setLayout, cursor, sync, clearDelay, onDelay]
    );

    const onTrack = useCallback(
        (name: string, color: string) => {
            propagateEvent({ s: cursor === saveNow ? new Date().getTime() : cursor, n: name, c: color });
            updateNow();
        },
        [cursor, saveNow, updateNow, propagateEvent]
    );

    const onInfo = useCallback(
        (info: string) => {
            propagateEvent({ s: cursor === saveNow ? new Date().getTime() : cursor, i: info });
            updateNow();
        },
        [cursor, saveNow, updateNow, propagateEvent]
    );
    useEffect(() => {
        if (mode === CellMode.OFF) {
            if (cursorEvent && cursorEvent.n) {
                propagateEvent({ ...cursorEvent, e: cursor });
                setCursorEvent(undefined);
                document.title = 'Time2Emphasize';
                updateNow();
            }
            setMode(CellMode.JUMP);
        }
    }, [mode, setMode, cursor, cursorEvent, setCursorEvent, propagateEvent, updateNow]);

    const downloadEvents = useCallback(() => {
        const allEvents = getEvents(0, Number.MAX_VALUE);
        const blob = csvTable(
            allEvents.map((e) => [e.s, e.n || e.i, e.c, e.e]),
            ['unixtimestamp', 'event', 'color', 'end']
        );
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', 'time-emphasize_events_' + format(new Date(), 'yyyy-MM-dd_HH-mm-ss') + '.csv');
        a.click();
    }, []);

    const downloadLayout = useCallback(() => {
        const blob = new Blob([layout], { type: 'application/json;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', 'time-emphasize_layout_' + format(new Date(), 'yyyy-MM-dd_HH-mm-ss') + '.json');
        a.click();
    }, [layout]);

    useMemo(() => {
        const eventsAtTime = getEvents(saveNow, saveNow);
        const events = eventsAtTime.filter((e) => e.s <= saveNow && e.n && (!e.e || e.e > saveNow));
        if (events.length) {
            const event = events[events.length - 1];
            setCursorEvent(event);
            document.title = event.n + ' - Time2Emphasize';
        } else {
            setCursorEvent(undefined);
            document.title = 'Time2Emphasize';
        }
    }, [saveNow]);

    useMemo(() => {
        // listen to sync/storage events/layouts
        getChannels(sync, propagateEvent, propagateLayout, (connectionMode: ConnectionMode) => {
            if ([ConnectionMode.SPECTATOR].includes(connectionMode)) {
                postChannel(sync.pairings['_spectator'], layout);
            }
            if ([ConnectionMode.SPECTATOR, ConnectionMode.PEEP].includes(connectionMode)) {
                postChannel(sync.pairings[connectionMode === ConnectionMode.SPECTATOR ? '_spectator' : '_peep'], cursorEvent);
            }
        });
        storagePairings(sync).forEach((p) => getStorage(sync, p, propagateEvent, propagateLayout));
    }, [sync, propagateEvent, propagateLayout, cursorEvent, layout]);

    return (
        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: theme.palette.background.default }}>
            <TimelineComponent
                cursor={cursor}
                focus={focus}
                zoom={zoom}
                setZoom={setZoom}
                saveNow={saveNow}
                onCursorChanged={onCursorChanged}
            />
            <TrackingComponent
                spectatorMode={false}
                mode={mode}
                cursorEvent={cursorEvent}
                action={action}
                messageBar={messageBar}
                onTrack={onTrack}
                onMode={setMode}
                layout={layout}
                onLayout={propagateLayout}
            />

            <Toolbar
                downloadEvents={downloadEvents}
                downloadLayout={downloadLayout}
                onInfo={onInfo}
                cursor={cursor}
                setCursor={setCursor}
                mode={mode}
                setMode={setMode}
                themeAction={themeAction}
                messageBar={messageBar}
                action={action}
                info={info}
                setInfo={setInfo}
                updateNow={updateNow}
                sync={sync}
                setSync={setSync}
                onEvent={propagateEvent}
            ></Toolbar>
        </Box>
    );
}
